// FlixListItem.js
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  FLIX_DISPLAY_UNLOCK_COUNT,
  SHORT_VIDEO_DURATION_SECONDS,
} from '../RemoteConfigKeys.js';

import FlixListItem from '../component/FlixListItem.jsx';
import getUsername from '../selector/getUsername.js';
import getOperationData from '../selector/getOperationData.js';
import getMessageData from '../selector/getMessageData.js';
import { replace } from '../action/navigationAction.js';
import fetchUser from '../action/fetchUser.js';
import fetchDrmLicenseAvailability from '../action/fetchDrmLicenseAvailability.js';
import fetchMessageDetail from '../action/fetchMessageDetail.js';
import getUserData from '../selector/getUserData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getAssetData from '../selector/getAssetData.js';
import getMeData from '../selector/getMeData.js';
import getMessageDataWithVariable, {
  VariableName,
} from '../selector/getMessageDataWithVariable.js';

const mapStateToProps = (state, { messageId }) => {
  const senderId = getMessageData(state, messageId, 'senderId');
  const assetIds = getMessageData(state, messageId, 'assetIds');
  const firstAssetId = assetIds?.[0];
  const meUsername = getMeData(state, 'username');
  const isAuthed = !!getMeData(state, 'id');
  return {
    senderId,
    senderName: getUserData(state, senderId, 'username'),
    displayedSenderUsername: getUsername(state, senderId, 'displayNameFirst'),
    originalTitle: getMessageDataWithVariable(
      state,
      messageId,
      'originalTitle',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    originalCaptionText: getMessageDataWithVariable(
      state,
      messageId,
      'originalCaptionText',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    badges: getMessageData(state, messageId, 'badges'),
    isPro: getMessageData(state, messageId, 'isPro'),
    shouldShowUnlockCount: getRemoteConfigData(
      state,
      FLIX_DISPLAY_UNLOCK_COUNT
    ),
    messageId,
    unlockPrice: getMessageData(state, messageId, 'unlockPrice'),
    canViewMedia: getMessageData(state, messageId, 'canViewMedia'),
    duration: getAssetData(state, [firstAssetId], 'duration'),
    contentType: getAssetData(state, [firstAssetId, 'contentType']),
    shortVideoDuration: getRemoteConfigData(
      state,
      SHORT_VIDEO_DURATION_SECONDS
    ),
    deviceWidth: getOperationData(state, ['device'], 'width'),
    isAuthed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    replace: data => dispatch(replace(data)),
    fetchUser: id => dispatch(fetchUser({ id })),
    fetchMessageDetail: ({ messageId }) =>
      dispatch(fetchMessageDetail({ id: messageId })),
    fetchDrmLicenseAvailability: ({ messageId, keySystem }) =>
      dispatch(fetchDrmLicenseAvailability({ messageId, keySystem })),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FlixListItem)
);

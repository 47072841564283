// Pagination.jsx
import React, { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { ButtonId } from '../resource/mixpanel.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import media from '../style/media.js';
import PrevIconSource from '../../img/ic-page-prev.svg';
import NextIconSource from '../../img/ic-page-next.svg';

const Pagination = ({
  maxVisibleButtons = 9,
  maxVisibleButtonsOnMobile = 5,
  totalPages = 0,
  currentPage = 0,
  isStickyOnMobile = true,
  style = undefined,
  replace,
}) => {
  const { t } = useTranslation();
  const mainAriaRef = useRef();
  const { pathname, search } = useLocation();

  const getPageUrl = page => {
    const searchParmas = new URLSearchParams(search);
    searchParmas.set('page', page);
    // (2024.10) DON'T use `searchParmas.size` since Safari under v17 doesn't support it.
    return `${pathname}${searchParmas.toString() ? `?${searchParmas.toString()}` : ''}`;
  };

  const onPageChange = event => {
    event.preventDefault();
    replace(event.target.href);
  };

  // Calculate the range of page numbers to display
  const getPageButtons = maxVisibleButtons => {
    const pageOffset = Math.floor(maxVisibleButtons / 2);
    let startPage = Math.max(currentPage - pageOffset, 1);
    let endPage = Math.min(currentPage + pageOffset, totalPages);

    // 1. Center the currentPage as much as possible,
    // 2. Adjust the buttons when the boundary is not as calculated before.
    if (totalPages <= maxVisibleButtons) {
      // Ensure all the pages are displayed if the `totalPages` is not larger than `maxVisibleButtons`.
      startPage = 1;
      endPage = totalPages;
    } else if (endPage - startPage < maxVisibleButtons - 1) {
      // Ensure there are always `maxVisibleButtons` if possible
      if (startPage === 1) {
        // If the amount on the left side is less than expected, increase the amount on the right to make up for it.
        endPage = Math.min(startPage + maxVisibleButtons - 1, totalPages);
      } else if (endPage === totalPages) {
        // If the amount on the right side is less than expected, increase the amount on the left to make up for it.
        startPage = Math.max(endPage - (maxVisibleButtons - 1), 1);
      }
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) pages.push(i);
    return pages.map(page => (
      <PageButton
        key={page}
        as={Link}
        to={getPageUrl(page)}
        onClick={onPageChange}
        data-element_id={ButtonId.Pagination.Page}
        data-tracking_payload={{ 'page.number': page }}
        $isCurrentPage={currentPage === page}
      >
        {page}
      </PageButton>
    ));
  };

  const handlePrevious = event => {
    if (currentPage > 1) onPageChange(event);
  };

  const handleNext = event => {
    if (currentPage < totalPages) onPageChange(event);
  };

  useEffect(() => {
    // Can't just use CSS to center the element.
    // (somehow it can't scroll/swipe to the leftmost after centering)
    setTimeout(() => {
      if (mainAriaRef?.current) {
        mainAriaRef.current.scrollLeft =
          (mainAriaRef.current.scrollWidth - mainAriaRef.current.clientWidth) /
          2;
      }
    }, 200); // wait a little bit to get the correct scrollWidth and clientWidth
  }, [currentPage, totalPages]);

  if (!currentPage || !totalPages || totalPages === 1) return null;

  return (
    <PaginationContainer style={style} $isStickyOnMobile={isStickyOnMobile}>
      <PageDirectionButton
        as={Link}
        to={getPageUrl(currentPage === 1 ? 1 : currentPage - 1)}
        onClick={handlePrevious}
        data-element_id={ButtonId.Pagination.PagePrevious}
        $isDisabled={currentPage === 1}
      >
        <PageDirectionIcon
          src={PrevIconSource}
          title={t('pagination_previous', {
            ns: TranslationNamespace.GENERAL,
          })}
          alt={t('pagination_previous', {
            ns: TranslationNamespace.GENERAL,
          })}
          aria-hidden
        />
        {t('pagination_previous', {
          ns: TranslationNamespace.GENERAL,
        })}
      </PageDirectionButton>

      <PaginationMainArea ref={mainAriaRef}>
        <PageButtonWrapper>
          {getPageButtons(maxVisibleButtons)}
        </PageButtonWrapper>
        <PageButtonWrapper $isMobileOnly={true}>
          {getPageButtons(maxVisibleButtonsOnMobile)}
        </PageButtonWrapper>
      </PaginationMainArea>

      <PageDirectionButton
        as={Link}
        to={getPageUrl(
          currentPage === totalPages ? currentPage : currentPage + 1
        )}
        onClick={handleNext}
        data-element_id={ButtonId.Pagination.PageNext}
        $isDisabled={currentPage === totalPages}
      >
        {t('pagination_next', {
          ns: TranslationNamespace.GENERAL,
        })}
        <PageDirectionIcon
          src={NextIconSource}
          title={t('pagination_next', {
            ns: TranslationNamespace.GENERAL,
          })}
          alt={t('pagination_next', {
            ns: TranslationNamespace.GENERAL,
          })}
          aria-hidden
        />
      </PageDirectionButton>
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  maxVisibleButtons: PropTypes.number,
  maxVisibleButtonsOnMobile: PropTypes.number,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  isStickyOnMobile: PropTypes.bool,
  style: PropTypes.object,
  replace: PropTypes.func.isRequired,
};

const PaginationContainer = styled.div`
  max-width: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 8px;
  ${media.mobile`
    padding: 8px 8px 32px;

    ${({ $isStickyOnMobile }) => {
      if ($isStickyOnMobile) {
        return css`
          position: sticky;
          bottom: 0;
          z-index: 1;
          background: rgba(25, 25, 25, 0.8);
        `;
      }
    }}
  `}
`;

const PageDirectionButton = styled.button`
  ${ResetButtonStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 2px 8px;
  gap: 4px;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: 0.016px;
  white-space: nowrap;
  ${media.mobile`
    font-size: 14px;
    letter-spacing: 0.014px;
  `}

  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return css`
        pointer-events: none;
        cursor: default;
        color: rgba(255, 255, 255, 0.2);
        & > img {
          opacity: 0.2;
        }
      `;
    }
  }}
`;

const PageDirectionIcon = styled.img`
  width: 16px;
  height: 16px;
  ${media.mobile`
    width: 12px;
    height: 12px;
  `}
`;

const PaginationMainArea = styled.div`
  display: flex;
  overflow-x: auto;

  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const PageButtonWrapper = styled.div`
  display: ${({ $isMobileOnly }) => ($isMobileOnly ? 'none' : 'inline-flex')};
  ${media.mobile`
    display: ${({ $isMobileOnly }) => ($isMobileOnly ? 'inline-flex' : 'none')};
  `};
  gap: 12px;
  ${media.mobile`
    gap: 16px;
  `};
`;

const PageButton = styled.button`
  ${ResetButtonStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  min-width: 36px;
  padding: 2px 8px;
  gap: 4px;

  border-radius: 4px;
  background-color: transparent;

  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.018px;

  ${media.mobile`
    min-width: 24px;
    font-size: 16px;
    letter-spacing: 0.016px;
  `}

  ${({ $isCurrentPage }) => {
    if ($isCurrentPage) {
      return css`
        background-color: #00d2be;
        color: #191919;
        pointer-events: none;
        cursor: default;
      `;
    }
  }}
`;

export default memo(Pagination);
